<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{$t('Informations')}}</h4>
        <span class="font-weight-bold">{{$t('The fields with (*) are required')}}</span>
        <hr class="mb-2">
        
        <b-row>
          <!-- Field: Customer -->
          <b-col cols="12" md="6">
            <b-form-group :label="$t('Customer')+'*'" label-for="customer">
              <vue-autosuggest v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- Field: Document -->
          <b-col cols="12" md="6">
            <b-form-group :label="$t('DNS')+'*'" label-for="dns">
              <validation-provider #default="{ errors }" name="dns" rules="required">
                <b-form-input id="dns" v-model="customerDnsData.dns" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

         
        </b-row>
        <b-row>
          <!-- Btn: Save -->
          <b-col v-if="!user.role.endsWith('VI')" cols="12" md="4">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{$t('Save')}}
            </b-button>
          </b-col>
        </b-row>
        
        
      </b-form>
    </validation-observer>
  </b-card>
</template>



<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'

import {
  ref,
  onUnmounted
} from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

import {
  VueAutosuggest
} from 'vue-autosuggest'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import customerStoreModule from "@/views/main/customer/customerStoreModule";

export default {
  components:{
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
  },
  
  data(){
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your customer ...",
      },
      limit: 3,
      selected: null,
    }
  },
  
  methods:{
    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let method = 'addCustomerDns'
          if(this.customerDnsData.id) method = 'updateCustomerDns'

          store.dispatch(`app-customer/${method}`, {
            customerDnsData: this.customerDnsData
          })
              .then(response => {
                this.msgDialog(this.$t('Successful registration'), 'success')
                router.push({
                  name: 'list-customer-dns'
                })

              })
              .catch(error => {
                this.msgDialog(this.$t('Failed to save'), 'error')
              })
        }
      })
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQuery = option.item.name
      this.customerDnsData.customerId = option.item.id
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-customer/fetchCustomers', {
        q: this.searchQuery,
        page: 1,
        perPage: 5
      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {
            console.log(error)
          })
    },
  },
  
  setup(){
    const customerDnsData = ref(null)
    const searchQuery = ref('')
    
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'
    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id != undefined) {

      store.dispatch('app-customer/fetchCustomerDns', {
        id: router.currentRoute.params.id
      })
          .then(response => {
            customerDnsData.value = response.data
            searchQuery.value = response.data.customerName;
          })
          .catch(error => {
            if (error.response.status === 404) {
              
            }
          })
    }else {
      customerDnsData.value = {
        dns:'',
        customerId: null,
      }
    }
    
    return {
      customerDnsData,
      searchQuery,
      user
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>